/**
 * Main Script File
 */
import lozad from 'lozad';

const body = document.body;
const scrollUp = 'scroll-up';
const scrollDown = 'scroll-down';
let lastScroll = 0;
let initialScrollEvent = true;

window.addEventListener('scroll', () => {
    if (!initialScrollEvent) {
        const currentScroll = window.pageYOffset;

        if (currentScroll <= 0) {
            body.classList.remove(scrollUp);
            return;
        }

        if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
            // down
            body.classList.remove(scrollUp);
            body.classList.add(scrollDown);
        } else if (
            currentScroll < lastScroll &&
            body.classList.contains(scrollDown)
        ) {
            // up
            body.classList.remove(scrollDown);
            body.classList.add(scrollUp);
        }
        lastScroll = currentScroll;
    } else {
        initialScrollEvent = false;
    }
});

const header = document.querySelector('header');
const mmToggle = document.querySelector('header .mm-toggle');
const mobileMenu = document.querySelector('#mobile-menu');
const openMenuClass = 'menu-open';

mmToggle.addEventListener('click', () => {
    if (! mmToggle.classList.contains(openMenuClass)) {
        const scrollY = window.scrollY;
        body.style.position = 'fixed';
        body.style.top = '-' + scrollY + 'px';
    } else {
        const scrollY = body.style.top;
        body.style.position = '';
        body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }

    body.classList.toggle(openMenuClass);
    header.classList.toggle(openMenuClass);
    mobileMenu.classList.toggle(openMenuClass);
    mmToggle.classList.toggle(openMenuClass);
});

var observer = lozad('.lozad');
observer.observe();

document.querySelectorAll('.bg-img').forEach(function(bgImg) {
    const picture = bgImg.querySelector('picture');
    let img_src = null;

    if (picture != null && picture.length < 1 && window.innerWidth < 400) {
        img_src = picture.querySelector('source').getAttribute('srcset');
    }

    if (!img_src || img_src.length === 0) {
        const img = bgImg.querySelector('img');

        if (img != null && img.toString().length > 1) {
            img_src = img.getAttribute('data-src');
        }
    }

    if (img_src) {
        if (bgImg.classList.contains('lozad')) {
            bgImg.setAttribute('data-background-image', img_src);
        } else {
            bgImg.style.backgroundImage = 'url(' + img_src + ')';
        }
    }
});

function throttle(fn, ms) { // Ryan Taylor comment - https://remysharp.com/2010/07/21/throttling-function-calls
    var time, last = 0;
    return function() {
        var a = arguments, t = this, now = +(new Date), exe = function() { last = now; fn.apply(t, a); };
        clearTimeout(time);
        (now >= last + ms) ? exe() : time = setTimeout(exe, ms);
    }
}
function hasClass(el, cls) {
    if (el.className.match('(?:^|\\s)'+cls+'(?!\\S)')) { return true; }
}
function addClass(el, cls) {
    if (!el.className.match('(?:^|\\s)'+cls+'(?!\\S)')) { el.className += ' '+cls; }
}
function delClass(el, cls) {
    el.className = el.className.replace(new RegExp('(?:^|\\s)'+cls+'(?!\\S)'),'');
}
function elementFromTop(elem, classToAdd, distanceFromTop, unit, delay) {
    var winY = window.innerHeight || document.documentElement.clientHeight,
        elemLength = elem.length, distTop, distPercent, distPixels, distUnit, i;

    for (let i = 0; i < elemLength; ++i) {
        distTop = elem[i].getBoundingClientRect().top;
        distPercent = Math.round((distTop / winY) * 100);
        distPixels = Math.round(distTop);
        distUnit = unit == 'percent' ? distPercent : distPixels;
        if (distUnit <= distanceFromTop) {
            if (!hasClass(elem[i], classToAdd)) {
                if (delay) {
                    setTimeout(function() {
                        addClass(elem[i], classToAdd);
                    }, delay * i);
                } else {
                    addClass(elem[i], classToAdd);
                }
            }
        }
    }
}

const animationClasses = document.querySelectorAll('.fade-in, .slide-in-left, .slide-in-right');
elementFromTop(animationClasses, 'active',  80, 'percent'); // as top of element hits top of viewport

window.addEventListener('scroll', throttle(function() {
    elementFromTop(animationClasses, 'active',  80, 'percent'); // as top of element hits top of viewport
}, 100), false);

const rowLoad = document.querySelectorAll('.row-load');

function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

for (let i = 0; i < rowLoad.length; i++) {
    // calc computed style
    const gridComputedStyle = window.getComputedStyle(rowLoad[i]);

    // items per row
    const perChunk = gridComputedStyle.getPropertyValue('grid-template-columns').split(' ').length;

    // Convert htmlcollection to array
    var inputArray = [].slice.call(rowLoad[i].children);

    // Slice cols into rendered rows
    const rows = sliceIntoChunks(inputArray, perChunk);

    // loop the rows
    for (let r = 0; r < rows.length; r++) {
        elementFromTop(rows[r], 'active', 80, 'percent', 200);

        window.addEventListener('scroll', throttle(function() {
            elementFromTop(rows[r], 'active',  80, 'percent', 200);
        }, 100), false);
    }
}

let products = document.querySelectorAll('.menu-item-has-children');

for (let i = 0; i < products.length; i++) {
    products[i].insertAdjacentHTML('beforeend', '<span class="menu-arrow"></span>');
}

document.querySelectorAll('.menu-item-has-children .menu-arrow').forEach(function(arrow) {
    arrow.onclick = function(){
        arrow.parentNode.classList.toggle('open');
        arrow.classList.toggle('open');
    };
});

document.querySelectorAll('.accordion-item .toggle').forEach(function(toggle) {
    toggle.onclick = function(){
        toggle.parentNode.classList.toggle('open');

        const expanded = toggle.parentNode.querySelector('.expanded');

        if (expanded.clientHeight) {
            expanded.style.height = 0;
        } else {
            expanded.style.height = expanded.scrollHeight + "px";
        }
    };
});

function initMap(el) {
    const myLatLng = {
        lat: Number(el.getAttribute('data-lat')),
        lng: Number(el.getAttribute('data-long')),
    };

    let map = new google.maps.Map(el, {
        center: myLatLng,
        zoom: 15,
        styles: [
            {
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#bdbdbd"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#dadada"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#b40000"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#dadada"
                    },
                    {
                        "lightness": 100
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#000000"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "transit.station",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#c9c9c9"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            }
        ]
    });

    new google.maps.Marker({
        position: myLatLng,
        map,
        icon: map_data.map_pin,
    });
}

let maps = document.querySelectorAll('.acf-map');

for (let i = 0; i < maps.length; i++) {
    initMap(maps[i])
}

const anchors = document.querySelectorAll('.travel-to');

anchors.forEach(function(anchor) {
    const id = anchor.getAttribute('href').replace('#', '');
    const block = document.getElementById(id);

    anchor.addEventListener('click', (e) => {
        e.preventDefault();
        let position = block.getBoundingClientRect();
        let blockTop = position.top + window.scrollY - 150;

        window.scrollTo({
            top: blockTop,
            left: position.left,
            behavior: 'smooth'
        });
    });
});